@import '~antd/dist/antd.css';
@import '../assent/icons/iconfont.css';
@import '../assent/icons1/iconfont.css';
@import '~antd-mobile/dist/antd-mobile.css';
html, body{
    margin: 0;
    padding: 0;
}
.App-Content {
    width: 100vw;
    height: 100vh;
    background: #E6E7E8;
    position: relative;
    .scan-home-page-content{
        width: 100%;
        height: 100%;
        max-width: 750px;
        margin: 0 auto;
        background:#fff;
        .scan-home-page-content-banner{
            height: 240px;
            width: 100%;
            position: relative;
            .btn{
                position: absolute;
                z-index: 3;
                font-size: 22px;
                color: #008FFE;
            }
            .zh-en-btn{
                right: 55px;
                top:20px;
            }
            .help-btn{
                right: 15px;
                top:20px;
            }
            .icon-bangzhu-css{
                font-size: 24px;
            }
            .has-meeting-bg{
                width: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                height: 240px;
                text-align: center;
                line-height: 240px;
                background: rgba(0, 0, 0,.5);
                .icon-shijian1-css{
                    color: rgba(219, 235, 255,.6);
                    font-size: 68px;
                }
            }
            img{
                height: 240px;
                width: 100%;
            }
        }
        .scan-home-page-content-header{
            height: 50px;
            line-height: 50px;
            text-indent: 10px;
            font-size: 24px;
            font-weight: 600;
            color: #111111;
        }
        .scan-home-page-content-date{
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #111111;
            text-indent: 10px;
            display: flex;
            .content{
                flex: 1;
                margin: 0px;
                .icon-rili-size{
                    color:#008FFE;
                    float: left;
                    font-size: 22px;
                }
            }
            .right-icon{
                height: 50px;
                line-height: 50px;
                margin-bottom: 0px;
                width: 45px;
                text-align: center;
                font-size: 12px;
                color: #666;
            }
        }
        .scan-home-page-content-time{
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #111111;
            text-indent: 10px;
            .label{
                color: #999;
                margin-right: 10px;
            }
            .icon-shijian-size{
                color:#008FFE;
                float: left;
                font-size: 22px;
            }
        }
        .scan-home-page-content-meetingtime{
            height: 30px;
            line-height: 30px;
            text-align: center;
            color:#008FFE;
            padding: 0px;
            margin: 0px;
        }
        .scan-home-page-content-title{
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            color: #008FFE;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 95%;
            margin: 0 auto;
            white-space: nowrap;
        }
        .scan-home-page-content-btn{
            height: 50px;
            line-height: 50px;
            text-align: center;
            p{
                width: 155px;
                text-align: center;
                font-size: 14px;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                color:rgb(34, 97, 219);
                border-radius: 20px;
                background: rgb(219, 235, 255);
                box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, .1);
            }
            .timeAction{
                color: #fff;
                background: #008FFE;
                border: 1px solid #008FFE;
            }
        }
        .scan-home-page-content-footer-btn{
            text-align: center;
            display: flex;
            position: absolute;
            bottom: 0px;
            width: 100vw;
            padding-top: 10px;
            height: 70px;
            border-top: 1px solid #ccc;
            p{
                flex: 1;
                display: inline-block;
                height: 45px;
                line-height: 45px;
                margin: 0 30px;
                // border: 1px solid #008FFE;
                color:rgb(34, 97, 219);
                background: rgb(219, 235, 255);
                width: 40%;
                font-size: 16px;
                font-weight: 600;
                color:  #008FFE;
                border-radius: 10px;
                a{
                    font-size: 16px;
                    font-weight: 600;
                    color:  #008FFE;
                }
            }
            .close-btn{
                background: rgb(255, 223, 223);
                color: rgb(255, 123, 85);
            }
        }
        .delay{
            p{
                display: block;
                margin: 0 5px;
                margin-bottom: 5px;
                display: inline-block;
            }
        }
    }
    .hoom-room-select{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        max-width: 750px;
        margin: 0 auto;
        overflow: hidden;
        .header{
            display: flex;
            height: 50px;
            text-align: center;
            background: #fff;
            margin-bottom: 10px;
            position: fixed;
            width: 100vw;
            top: 0px;
            div{
                flex: 1;
            }
            .header-time-select{
                flex: 1;
                a{
                    display: inline-block;
                    width: 95%;
                    height: 30px;
                    line-height: 27px;
                    margin-top: 10px;
                    border: 1px solid #008FFE;
                    border-radius: 2px;
                    text-align: center;
                    .icon-time-size{
                        font-size: 15px;
                        margin-left: 5px;
                    }
                }
            }
            .header-time-space{
                flex: 1.5;
                .header-time-btn{
                    width: 95%;
                    display: flex;
                    margin: 0 auto;
                    margin-top:10px;
                    height: 30px;
                    line-height: 27px;
                    border-radius: 2px;
                    border: 1px solid #008FFE;
                    overflow: hidden;
                    p{
                        flex: 1;
                        height: 30px;
                        overflow: hidden;
                        color: #008FFE;
                    }
                    .active{
                        background: #008FFE;
                        color: #fff;
                    }
                    :nth-child(1){
                        border-right: 1px solid #008FFE;
                    }
                }
            }
            .header-time-filter{
                flex: .8;
                a{
                    display: inline-block;
                    color: #666;
                    width: 95%;
                    height: 30px;
                    line-height: 30px;
                    margin-top: 10px;
                    border-radius: 2px;
                    text-align: center;
                    text-align: end;
                    .icon-caidan-size{
                        font-size: 24px;
                        margin-right: 10px;
                    }
                    .way-icon{
                        margin-left: 3px;
                        font-size: 12px;
                    }
                }
            }
        }
        .hoom-room-select-content{
            flex: 1;
            margin-top: 50px;
            overflow: auto;
            .no-room{
                width: 100%;
                height: 200px;
                line-height: 200px;
                text-align: center;
                font-size: 18px;
                color: #888;
            }
            .room-item {
                background: #fff;
                padding: 20px 10px;
                display: flex;
                border-bottom: 1px solid #efefef;
                .room-item-image{
                    height: 76px;
                    width: 95px;
                    margin-right: 5px;
                    img{
                        width: 100%;
                        // height: 100%;
                    }
                }
                .room-item-des{
                    flex: 1;
                    margin-left: 5px;
                    
                    dt{
                        height: 35px;
                        font-size: 20px;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    dd{
                        // height: 25px;
                        .room-item-des-dd-p{
                            .room-item-des-dd-p-label{
                                .icon-gongzuozhuangtai-size{
                                    font-size: 20px;
                                    color: #008FFE;
                                }
                                .icon-shijians-size{
                                    font-size: 24px;
                                    color: #008FFE;
                                }
                                .room-item-des-dd-p-status{
                                    color: #999;
                                    font-size: 17px;
                                    margin-left: 5px;
                                }
                                .room-item-des-dd-p-dst{
                                    font-size: 17px;
                                    color: #666;
                                }
                            }
                        }
                        .room-item-tips{
                            display: inline-block;
                            height: 25px;
                            line-height: 22px;
                            padding: 0 5px;
                            border:2px solid #E6E7E8;
                            color: #999;
                            border-radius:6px;
                            font-size: 12px;
                        }
                    }
                }
            }
            .isBusy{
                // background: #efefef;
            }
        }
    }
    .nav-content{
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        .nav-content-title{
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 18px;
        }
    }

}
